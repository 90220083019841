import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsCategoryList from '../views/NewsCategoryList.vue'
import NewsArticle from '../views/NewsArticle.vue'
import PresaleCategoryList from '../views/PresaleCategoryList.vue'
import PresaleArticle from '../views/PresaleArticle.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import Panel from '../views/Panel.vue'

import axios from "axios"
axios.defaults.withCredentials = true;

import php_url from "../main.js"


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/news-category-list/:newsCateg',
    name: 'news-category-list',
    component: NewsCategoryList,
    props: true
  },
 
  {
    path: '/:categoryUrl/:titleUrl',
    name: 'news-article',
    component: NewsArticle,
    props: true
  },
  {
    path: '/presale',
    name: 'presale-category-list',
    component: PresaleCategoryList,
    props: true
  },
  {
    path: '/presale/:titleUrl',
    name: 'presale-article',
    component: PresaleArticle,
    props: true
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    props: true
  },
  {
    path: '/login-area-admin',
    name: 'login',
    component: Login,
    meta: {
      authRequired: 'false',
    },
  },
  {
    path: '/cryptochainleak-admin-area',
    name: 'panel',
    component: Panel,
    meta: {
      authRequired: 'true',
    },
  },
    
    // meta: {
    //   title: "Social Innovation",
    // }
  
  {
    path: '/:catchAll(.*)',
    name: 'home',
    component: HomeView
  }
 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to,from,next) => {
  
  if (to.meta.authRequired === 'true') {

    axios.post(php_url.php_url,JSON.stringify({action:"ckAccess"})).then((res) => {

      if (res.data.access == 1) {
        next();
      } else {
        router.push({
          name: 'login'
        })
      }
    })
  } else {
    next();
  }
  
});
 

export default router
