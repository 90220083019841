<template>
    <Header1  />
  <div class="page-content animsition">
    <div class="container">
        <div class="portfolio">
            <div class="row">
               
                <ul class="portfolio-items list-unstyled" id="grid">
                    <transition-group name="fade" tag="ul">
                        <li v-for="presale in filteredItems" :key="presale.id" class="col-md-4 col-sm-4 col-xs-6 fade-item" :title="presale.title">
                            <figure class="portfolio-item">
                                <a :href="'/presale/'+presale.title_url" class="port-img">
                                    <img :src="presale.img_url" alt="" class="img-responsive">
                                 
                                </a>
                                <figcaption class="portfolio-text">
                                    <h3>{{presale.title}}</h3>
                                    <p>{{presale.content100}}...</p>
                                </figcaption>
                            </figure>
                        </li>
                    </transition-group>
                   
                </ul> <!--end portfolio grid -->
            </div> <!--end row -->
            <ul class="pagination">
                <li><a @click.prevent="previousPage" :disabled="currentPage === 1" href="#">&#8701;</a></li>
                <li v-for="(page,index) in totalPages" :key="index" :class="{active: page === currentPage}"><a  @click.prevent="changePage(page)" href="#">{{page}} </a></li>
               
              
                <li ><a @click.prevent="nextPage" :disabled="currentPage === newsTotalPages" href="#">&#8702;</a></li>
            </ul>
        </div>
    </div>
</div>
<Footer1/>
</template>

<script>

    import Header1 from '@/components/HeaderFooter/Header1'
    import Footer1 from '@/components/HeaderFooter/Footer1'

    import axios from "axios"
    import php_url from "../main.js"

    export default {
    inheritAttrs: false,
    name: 'PresaleCategoryList',
    components: {
        Header1, Footer1
    },
    props: {
        
    },
    data() {
        return {
            presaleArticles: [],
            latestNewsPageSize: 10, 
            currentPage: 1,
            totalPages: "",
            filteredItemsLength: ""
        }
    },
    methods: {

       fetchItems() {
    
            axios.post(php_url.php_url,JSON.stringify({action:"getPresaleArticles",length:""})).then((res) => {
                this.presaleArticles = res.data;
                this.filteredItemsLength = Math.ceil(this.presaleArticles.length / this.latestNewsPageSize);
            })
        },
        
        nextPage() {
        if(this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        },
        previousPage() {
            if(this.currentPage > 1) {
                this.currentPage--;
            }
        },
        changePage(page) {
            this.currentPage = page;
        },
    
    },
    computed:{
        filteredItems() {
            const startIndex = (this.currentPage - 1) * this.latestNewsPageSize;
            const endIndex = startIndex + this.latestNewsPageSize;
          
            const filteredItems = this.presaleArticles.slice(startIndex, endIndex);
            const itemsLength = this.presaleArticles.length;
            
            this.filteredItemsLength = this.presaleArticles.length;

            return filteredItems;
          

        },
        newsTotalPages() {
            if(this.filteredItemsLength == 0) {
                this.filteredItemsLength = 1;
            }
            this.totalPages = Math.ceil(this.filteredItemsLength / this.latestNewsPageSize);

            if(this.totalPages == 1) {
                this.currentPage = 1;
            }
            return this.totalPages;
        }
    },
  
    mounted() { 
        
        this.fetchItems();
                    

        }
    }
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .portfolio-items figure a {
        transition: opacity 0.4s, transform 0.4s;
    }

    .portfolio-items figure a:hover {
        opacity: 0.5;
        transform: scale(1.1);
    }

    .fade-item {
        transition: opacity 0.5s;
    }

    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
    opacity: 0;
    }

    

</style>
