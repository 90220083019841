<template>
  
  <router-view/>

</template>

<script>
  import { useRoute } from 'vue-router';

  // import Header1 from '@/components/HeaderFooter/Header1'
  // import Footer1 from '@/components/HeaderFooter/Footer1'

  export default {
    name: 'HomeView',
    components: {
      // Header1, Footer1
    },
    data() {
      return {
       
      }
    },
    mounted() {
      
    }
  }

</script>

<style lang="scss">
#app {
  
}

</style>
