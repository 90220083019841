<template>
  <Header1/>
  <div class="container-fluid d-flex justify-content-center align-items-center vh-100">
      <div id="login-content" class="text-center">
          <h4><fa style="margin-right:10px;" icon="arrow-right" size="1x"/><b>PANEL LOGIN</b></h4>
          <hr>
          <div id="formular">
              <form class="flex-column py-3" @submit.prevent="onSubmit">
                  <h3><b>AUTENTIFICARE</b></h3>
                  <hr class="my-1 text-light">
                
                  <input type="text" placeholder="User" class="form-control align-self-center text-center" v-model="User">
                  <input type="password" placeholder="Parolă" class="form-control align-self-center text-center" v-model="Parola">
                  <div v-if="Error == '0'" style="height: 20px;width:300px;" class="alert alert-danger my-3 d-flex mx-auto align-items-center justify-content-center" role="alert">
                      Credențialele nu sunt corecte!
                  </div>
                  <button style="margin: 15px 0" class="btn btn-success align-self-center" id="loginBtn" @click="Login">Login</button>
                 
              </form>
          </div>
      </div>
  </div>
</template>


<script>
import { defineComponent } from 'vue';

import Header1 from '@/components/HeaderFooter/Header1'
import Footer1 from '@/components/HeaderFooter/Footer1'


import axios from "axios"
axios.defaults.withCredentials = true;

import php_url from "../main.js"

import { load } from 'recaptcha-v3'

export default defineComponent({
  inheritAttrs: false,
  components: {
    Header1, Footer1
  },
  name: 'Prezentare',
  data() {
    return {
      User: "",
      Parola: "",
      Error: "",
      levelAccess: "",
      token: ""
    }
  },
  methods: {
     async Login() {
      //  // (optional) Wait until recaptcha has been loaded.
      //  await this.$recaptchaLoaded()

      // // Execute reCAPTCHA with action "login".
      // const token = await this.$recaptcha('login')

      this.recaptchaToken().then(() => {
        axios.post(php_url.php_url,JSON.stringify({action:"LoginPanel",username: this.User,parola: this.Parola,token: this.token})).then((res) => {
            console.log(res.data);
            if(res.data == 1) {
              this.$router.push('cryptochainleak-admin-area');
            } else {
              this.$router.push('/login-area-admin');
            }
          })
      })

    },
    async recaptchaToken() {
      const recaptcha =  await load('6LfJuMAaAAAAAOTid5dr8Iku3Wd_jwnpS-W4l9CE',{autoHideBadge: false})
      recaptcha.showBadge()
      let token = await recaptcha.execute('login')

      this.token = token

      setTimeout(function() {
        recaptcha.hideBadge()
      }.bind(this),3000)

    },

  },
  mounted() {
   
  }
    
})
</script>

<style>
  #login-content {
    border-radius: 5px;
    margin: 3px auto;
    padding: 20px;
    font-family: sans-serif;
    width: 100%;
    max-width: 400px;
  }

  #login-content h4 {
    text-align: center;
  }

  #login-content #formular {
    border: solid 2px #155185;
    border-radius: 5px;
    background-color: #155185;
    padding: calc(var(--bs-gutter-x) * .5);
    position: relative;
    margin: 0 auto;
    margin-top: 27%;
  }

  #login-content #formular input {
    margin: 8px auto;
    border-radius: 5px;
    height: 40px;
    font-size: 17px;
    padding: 7px;
    width: 74%;
  }

  #login-content #formular #loginBtn { 
    width: 47%;
  }

  #login-content #formular h3 {
    letter-spacing: 4px;
    color: #ffffff;
    text-shadow: 3px 4px 5px #222222;
  }

  #login-content #inregistrare-uti {
    color: #bbb744;
  }

  #login-content #inregistrare-uti:hover {
    color: #bbb7448a;
  }
</style>
