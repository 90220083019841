<template>
  <Header1/>
  <div id="app" class="container-fluid">
    <h2 style="background: rgb(19 36 61 / 96%);color: #eaeaea;border-radius:5px;padding: 6px 0;" class="text-center">Control Panel</h2>
    <hr>

    <div class="row">
        <!-- Left Side -->
        <div class="col-sm-2 bg-info" id="panel-ls">
            <ul class="list-group">
                <li class="d-flex list-group-item cursor-pointer my-10 bg-transparent" :class="{white: section.clicked,backgroundNone: !section.clicked}" v-for="section in sections" @click="selectSection(section)">
                  <fa class="pr-10" :icon="['fas', section.icon]" /> <b>{{ section.title }}</b>
                </li>
            </ul>
        </div>
        <!-- Right Side -->
        <div style = "background-color: #355685f5;padding-top: 10px;border-radius: 5px;" class="col-sm-10 bg-danger" id="panel-rs">
          <div class="pull-right">
            <button @click.prevent="saveArticle()" class="btn btn-primary" style="margin-right:5px;">Save</button>
            <button @click.prevent="resetFields()" class="btn btn-primary">Reset fields</button>
            <button @click.prevent="Logout()" class="btn btn-primary" style="margin-right:5px;">Logout</button>
          </div>
          
          <div v-if="Message" style="width:65%;" class="alert my-3 d-flex mx-auto align-items-center justify-content-center p-2" :class="reqSuccess ? 'alert-success' : 'alert-danger'" role="alert">
            {{Message}}
          </div>
            <h2 style="color: #eee" class="mt-0">{{ selectedSection.title }}</h2>
            <hr style="margin-top:0;">
            <label style="color:#ffd700;">Article list</label>
            <ul  id="articles-label" style="margin-bottom:0;overflow-y: auto;height: 165px;" class="list-group" >
              <li style="font-size:19px;padding: 10px 12px;align-items: center;border-radius: 2px;margin: 4px 0;color: white;border: 0;" class="d-flex list-group-item cursor-pointer my-10"  :class="{cadetBlue: article.clicked,backgroundNone: !article.clicked}" v-for="article in articles"  :key="article.id" @click="selectArticle(article)" id="article-list">
                {{ article.title }}
              </li>
            </ul>

            <div style = "align-items: center;column-gap: 45px;" class="grid_flex">
              <label class="my-3 text-start"><h6 style = "font-size: 16px;color:rgb(255, 215, 0);" class="mb-1 text-start"><b>Upload Image</b></h6>
                <input @change="handleFileUpload" ref="file" class="my-1 form-control" type="file" id="upload-image" >
              </label>
  
              <div class="preview-container ">
                <img v-if="imageUrl" :src="imageUrl" alt="Preview Image" class="preview-image">
                <span style="color:#ffffff;" v-else>No image selected</span>
              </div>
            </div>
           
            <label style="width:100%;color:#ffd700;"> Category
              <select style="min-width:100px;font-size: 15px;border-radius: 5px;margin-top: 2px;" @change="changeCategory" v-model="selectedCategory" class="form-control my-10" id="categ-selector">
                <!-- <option class="fw-bold" >&laquo; Alege &raquo;</option> -->
                <option class="fw-bold" v-for="categ in category" :key="category.id" :value="categ.id">{{ categ.name }}</option>
              </select>
            </label>

            <label style="width:100%;color:#ffd700;"> Title URL</label>
            <input style="margin-bottom: 13px;" v-model="articleTitleUrl" class="form-control w-50" />

            <label style="width:100%;color:#ffd700;"> Title</label>
            <input style="margin-bottom: 13px;" v-model="articleTitle" class="form-control w-50" />
            

            <label style="width:100%;color:#ffd700;"> Keywords</label>
            <input style="margin-bottom: 13px;" v-model="articleKeywords" class="form-control w-50" />

            <label style="width:100%;color:#ffd700;"> Description</label>
            <textarea style="margin-bottom: 13px;" v-model="articleDescription" class="form-control w-50" id="description" rows="5"></textarea>
            
            <label style="width:100%;color:#ffd700;"> Content</label>
            <textarea v-model="textareaContent" class="form-control" id="content"></textarea>
           
        </div>
    </div>
</div>
</template>


<script>
import { defineComponent } from 'vue';

import Header1 from '@/components/HeaderFooter/Header1'

import axios from "axios"
axios.defaults.withCredentials = true;

import php_url from "../main.js"

export default defineComponent({
  inheritAttrs: false,
  components: {
    Header1
  },
  name: 'Panel',
  data() {
    return {
      sections: [
        { id: 1, title: 'Articles',icon: "house",clicked: true },
  
      ], // Populate with your sections
      articles: [],
        // { id: 1, title: 'Art1', content: 'Welcome to our website!',clicked: false },
        // { id: 2, title: 'Art2', content: 'We are a company that values...',clicked: false },
        // { id: 3, title: 'Art3', content: 'You can reach us via...',clicked: false },
        // { id: 1, title: 'Art1', content: 'Welcome to our website!',clicked: false },
        // { id: 2, title: 'Art2', content: 'We are a company that values...',clicked: false },
        // { id: 3, title: 'Art3', content: 'You can reach us via...',clicked: false },
       
      category: [], // Populate with your types
      selectedSection: {id: 1, title: 'Articles', content: 'Welcome to our website!'},
      selectedCategory: '1',
      articleId: '',
      articleTitleUrl: "",
      articleTitle: "",
      articleKeywords: "",
      articleDescription: "",
      textareaContent: '',
      Message: '',
      reqSuccess: false,
      imageUrl: null,
      imageUpload: null
    
    }
  },
  methods: {
    getCategories() {
            axios.post(php_url.php_url,JSON.stringify({action:"getCategories"})).then((res) => {
              this.category = res.data;

              this.category.push({
                  id: "presale",
                  name: "Presale"
              });
            }) 
        },
    getLatestArticles() {
      // console.log(this.acticleId);
      let length = "";
      if(this.selectedCategory) {
        let length = "8";
      } 
        axios.post(php_url.php_url,JSON.stringify({action:"getLatestArticles",category:this.selectedCategory,length:length})).then((res) => {
            this.articles = res.data;

            // console.log(this.articles);
        })
    },
    changeCategory() {
      this.getLatestArticles()
      this.resetFields()
    },
    selectSection(section) {
          this.selectedSection = section;

          this.sections.forEach(section => {
          section.clicked = false;
          });

          section.clicked = true
        },
        // Function to decode HTML entities
      decodeHTMLEntities(text) {
          var doc = new DOMParser().parseFromString(text, "text/html");
          return doc.documentElement.textContent;
      },
    selectArticle(article) {

          this.articleId = article.id

          if(article.category_id === undefined) {
            this.selectedCategory = "presale"
          } else {
            this.selectedCategory = article.category_id
          }

          this.articleTitleUrl = article.title_url
          this.articleTitle = article.title
          this.articleKeywords = article.keywords
          this.articleDescription = article.description
          this.textareaContent = this.decodeHTMLEntities(article.content)

          this.imageUrl = article.img_url;
          this.imageUpload = article.img_url

          this.articles.forEach(article => {
            article.clicked = false;
          });

          article.clicked = true
           
        },
    resetFields() {
        this.articleId = ""
        
        this.articleTitleUrl = ""
        this.articleTitle = ""
        this.articleKeywords = ""
        this.articleDescription = ""
        this.textareaContent = ""
        this.imageUrl = ""
        this.imageUpload = ""

        this.articles.forEach(article => {
          article.clicked = false;
        });
      },
   
    saveArticle() {
      
        if(!this.articleTitleUrl) {
            this.Message = "Write URL title!"
        } else if(!this.articleTitle) {
            this.Message = "Write article title!"
        } else if(!this.imageUrl) {
            this.Message = "Attach image!"
        } else if(!this.articleDescription) {
            this.Message = "Write description!"
        } else if(!this.articleKeywords) {
            this.Message = "Write keywords!"
        } else if(!this.textareaContent) {
            this.Message = "Write textarea content!"
        } else if(!this.selectedCategory) {
            this.Message = "Select category!"
        } else {
            let formData = new FormData();
            formData.append('action',"saveArticle")

            formData.append('article_id',this.articleId)
            formData.append('file',this.imageUpload)
            formData.append('article_category',this.selectedCategory)
            formData.append('title_url',this.articleTitleUrl)
            formData.append('article_title',this.articleTitle)
            formData.append('article_keywords',this.articleKeywords)
            formData.append('article_description',this.articleDescription)
            formData.append('article_content',this.textareaContent)

            let data = {action:"uploadAnunt",titlu_anunt: this.titluAnunt,continut_anunt: this.continutAnunt}

            axios({
                url: php_url.php_url,
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            }).then((res) => {
                if(res.data == 1) {
                    this.reqSuccess = true
                    this.Message = "Successfully saved!"
                    this.getLatestArticles()
                    this.resetFields()
                } else if(res.data == 0) {
                    this.Message = "The file attached isn't an actual image!"
                } else if(res.data == 2) {
                    this.Message = "The attached file already exists in the database!"
                } else {
                    this.Message = "Unknown error!"
                }
            })
        }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Read the file as a URL
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
        this.imageUpload = file;
      };
      reader.readAsDataURL(file);
    },
    Logout() {
      axios.post(php_url.php_url,JSON.stringify({action:"logout"})).then((res) => {
           if(res.data == 0) {
            this.$router.push('/login-area-admin');
           }
        })
    }

  },
  mounted() {
    this.getCategories()
    this.getLatestArticles()
  }
    
})
</script>

<style scoped>

  input,textarea {
    font-size: 17px;
    line-height: 18px;
  }

  .cadetBlue {
    background-color:#5f9ea0;
  }

  .backgroundNone {
    background: none;
  }

  .white {
    background-color:#ffffff;
  }

  .preview-container {
    margin-top: 10px;
  }

  .preview-image {
    max-width: 200px;
    max-height: 200px;
  }

  @media (min-width: 768px){
    textarea#content {
      height: calc(100% - 870px);
    }
  }

  @media (max-width: 768px){
    textarea#content {
      height: calc(100% - 845px);
    }
  }


  
</style>
