import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'


library.add(fas,fab)

const urlApiDev = 'http://cryptochainleak.localhost/public/controllers/routes.php'
const urlApiProd = 'https://cryptochainleak.com/controllers/routes.php'

export default {
    php_url: process.env.NODE_ENV === 'development' ? urlApiDev : urlApiProd
  }
  

createApp(App).component('fa',FontAwesomeIcon).use(router).mount('#app')
