<template>
    <Header1/>
    <div class=" page-content animsition">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="details-body">
                        <div class="post_details stickydetails" @keydown="escapeClosePopup($event)">
                            <header class="details-header">
                                <div class="post-cat"><a :href="newsArticle.category_url">{{newsArticle.category}}</a></div>
                                <h2>{{newsArticle.title}}</h2>
                                <div class="element-block">
                                    <div class="entry-meta">
                                        <span class="entry-date"><i class="fa fa-calendar-o" aria-hidden="true"></i><time :date="newsArticle.created_at">{{newsArticle.date}}</time></span> 
                                    </div>
                                </div>
                            </header>
                            
                            <a>
                                <img :src="newsArticle.img_url"  class="alignright img-responsive" :title="newsArticle.title" :alt="newsArticle.title">
                            </a>
                            <p><span v-html="renderHtmlWithImage" @click="showImagePopup($event)" ></span></p>

                            <div v-if="showPopup" class="image-popup">
                                <img :src="imageSrc" />
                                <button @click="closePopup">X</button>
                            </div>
                            
                        </div>
                        <!-- /.End of post details -->
                        <div class="stickyshare">
                            <aside class="share_article">
                                <a href="#" class="boxed_icon facebook" :data-share-url="newsArticle.data_share_url" data-share-network="facebook" data-share-text="Share this awesome link on Facebook" data-share-title="Facebook Share" data-share-via="" data-share-tags="" data-share-media=""><i class="fa fa-facebook"></i></a>
                                <a href="#" class="boxed_icon twitter" :data-share-url="newsArticle.data_share_url" data-share-network="twitter" data-share-text="Share this awesome link on Twitter" data-share-title="Twitter Share" data-share-via="" data-share-tags="" data-share-media=""><i class="fa fa-twitter"></i></a>
                               
                                <a href="#" class="boxed_icon pinterest" :data-share-url="newsArticle.data_share_url" data-share-network="pinterest" data-share-text="Share this awesome link on Pinterest" data-share-title="Pinterest Share" data-share-via="" data-share-tags="" data-share-media=""><i class="fa fa-pinterest-p"></i></a>
                               
                            </aside>
                        </div>
                        <!-- /End of share icon -->
                    </div>
                    <!-- /.End if details body -->
                   
                    <div class="post_related">
                        <h3 class="related_post_title">You Might Also Like...</h3>
                        <div class="row">
                            <div v-for="news in latestNews" :key="news.id" class="col-sm-3">
                                <article class="post_article item_related">
                                    <a class="post_img" :href="'/'+news.categ_url+'/'+news.title_url">
                                        <figure>
                                            <img class="img-responsive" :src="hostname+'/'+news.img_url" :title="news.title" :alt="news.title">
                                        </figure>
                                    </a>
                                    <h4><a :href="'/'+news.categ_url+'/'+news.title_url">{{news.title100}} ...</a></h4>
                                </article>
                                <!-- /.End of related post -->
                            </div>
                           
                        </div>
                    </div>
                    <!-- /.End of  related post -->
 
                </div>
            </div>
        </div>
    </div>
    <Footer1/>
</template>

<style>

    .post_related article a, .post_details a img {
        transition: opacity 0.4s, transform 0.4s;
    }

    .post_related article a:hover, .post_details a img:hover {
        opacity: 0.5;
        transform: scale(1.1);
    }

    .post_details p img{
        width: 100%;
        padding: 10px;
        cursor: pointer;
    }
    
    .image-popup button {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 30px;
        width: 30px;
        font-size: 23px;
        background-color: aqua;
        border: 0;
        font-weight: bold;
    }

    .post_details button:hover {
        background-color: rgb(208, 236, 236);
        color:red;
    }

    .image-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 999;
    }

    .image-popup img {
        max-width: 80%;
        max-height: 80%;
    }

</style>


<script>

import Header1 from '@/components/HeaderFooter/Header1'
import Footer1 from '@/components/HeaderFooter/Footer1'

import axios from "axios"
import php_url from "../main.js"

export default {
  inheritAttrs: false,
  name: 'NewsArticle',
  components: {
    Header1, Footer1
  },
  props: {
    
  },
  data() {
            return {
                hostname: "",
                newsArticle: "",
                htmlContent: "",
                imageSrc: '',
                showPopup: false,
                latestNews: [],
            }
        },
  methods: {
    showImagePopup(event) {
      if (event.target.tagName === 'IMG') {
        const imageUrl = event.target.getAttribute('src');
        this.imageSrc = imageUrl;
        this.showPopup = true;
      }
    },
    closePopup() {
      this.showPopup = false;
    },
    escapeClosePopup(event) {
      if (event.key === 'Escape') {
        this.closePopup();
      }
    },
    getNewsArticle(titleUrl) {
            axios.post(php_url.php_url,JSON.stringify({action:"getNewsArticle",title_url:titleUrl})).then((res) => {
                this.newsArticle = res.data
                this.newsArticle.data_share_url = "https://cryptochainleak.com/"+this.newsArticle.category_url+"/"+this.newsArticle.title_url
                this.newsArticle.category_url = "/news-category-list/"+this.newsArticle.category_url
               
                this.newsArticle.category = this.newsArticle.category
                this.newsArticle.title = this.newsArticle.title
                this.newsArticle.content = this.newsArticle.content
                this.newsArticle.date = this.newsArticle.date

                this.htmlContent = this.newsArticle.content
            })
    },
    getLatestNews() {
            axios.post(php_url.php_url,JSON.stringify({action:"getLatestNews",length:"4"})).then((res) => {
                this.latestNews = res.data;
            })
        },
    
  },
  computed: {
    renderHtmlWithImage() {
      const imageTagRegex = /<img.*?src=["'](.*?)["']/;
      const imageTagMatch = this.htmlContent.match(imageTagRegex);
      
      if (imageTagMatch) {
        const imageTag = imageTagMatch[0];
        const imagePath = imageTagMatch[1];
        const resolvedImagePath = imagePath;
        const html = this.htmlContent.replace(imageTag, imageTag.replace(imagePath, resolvedImagePath));
        return html;
      }
      
      return this.htmlContent;
    }, 
  },
  mounted() {
    if(window.location.port) {
        this.hostname = "https://"+window.location.hostname+":"+window.location.port
    } else {
        this.hostname = "https://"+window.location.hostname
    }
   
    const titleUrl = this.$route.params.titleUrl
    const categoryUrl = this.$route.params.categoryUrl

    this.getNewsArticle(titleUrl)
    this.getLatestNews()

    window.addEventListener('keydown', this.escapeClosePopup);
    },

    beforeUnmount() {
        window.removeEventListener('keydown', this.escapeClosePopup);
    }
}
</script>

