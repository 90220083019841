<template>
    <Header1/>
    <div class="page-content animsition">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="contact-info">
                        <div class="contact-address">
                            <div class="contact-text">
                                <h3>Contact Info</h3>
                                <p>Do you have any questions, comments, or suggestions? <br>Are you interested in collaborating with us? <br>Feel free to send us a message, and we'll be delighted to connect with you.</p>
                                <br>
                            </div>
                            
                            <div class="address-info">
                                <div class="media">
                                    <div class="pull-left">
                                        <img src="../assets/imgs/other/icon/email.png" class="img-responsive" alt="">
                                    </div>
                                    <div class="media-body">
                                        <h4 style="margin-bottom: 10px;" class="addon-title">Email Address</h4>
                                       
                                        <div class="addon-text">contact@cryptochainleak.com</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="contact-form">
                        <h3>Feedback</h3>
                       
                        <div class="form-group">
                            <textarea placeholder="Message" class="form-control" id="textarea" rows="5"  v-model="message"></textarea>
                        </div>
                        <div class="form-group row">
                            <label for="name" class="col-sm-3 col-md-2 col-form-label">Name *</label>
                            <div class="col-sm-6 col-md-5">
                                <input class="form-control" id="name2" type="text" v-model="name">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="email" class="col-sm-3 col-md-2 col-form-label">Email *</label>
                            <div class="col-sm-6 col-md-5">
                                <input class="form-control" id="email2" type="text" v-model="email">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="website" class="col-sm-3 col-md-2 col-form-label">Website</label>
                            <div class="col-sm-6 col-md-5">
                                <input class="form-control" id="website" type="text" v-model="website">
                            </div>
                        </div>
                        <a href="#" @click.prevent="submitContact" class="btn link-btn">Submit ⇾</a>
                        <p v-if="alert" class="alertMessage" :class="reqSuccess ? 'alert-success' : 'alert-danger'">{{alert}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer1/>
</template>

<style>

.contact-form .alertMessage {
    margin-top: 10px;
    font-size: 18px;
    padding: 5px;
    font-family: sans-serif;
}

  .contact-form .alert-danger {
    color: #ff0000;
  }

  .contact-form .alert-success {
    color: #1c8a00;
  }
    
</style>


<script>

import Header1 from '@/components/HeaderFooter/Header1'
import Footer1 from '@/components/HeaderFooter/Footer1'

import axios from "axios"
import php_url from "../main.js"

import { load } from 'recaptcha-v3'

export default {
  inheritAttrs: false,
  name: 'Contact',
  components: {
    Header1, Footer1
  },
  props: {
    
  },
  data() {
            return {
               alert: "",
               name: "",
               email: "",
               website: "",
               message: "",
               reqSuccess: false,
               token: ""
            }
        },
  methods: {
    submitContact() {

            if(this.name === "") {
                this.reqSuccess = false
                this.alert = "* Name field is mandatory!";
            } else if(this.email === "") {
                this.reqSuccess = false
                this.alert = "* Email field is mandatory!";
            } else if(this.message === "") {
                this.reqSuccess = false
                this.alert = "* Message field is mandatory!";
            } else {
                const isValid = this.isEmailValid(this.email);

                if(!isValid) {
                    this.reqSuccess = false
                    this.alert = "* The email is not in the right format!";
                } else {
                    this.recaptchaToken().then(() => {
                        axios.post(php_url.php_url,JSON.stringify({action:"submitContactForm",name:this.name,email:this.email,website:this.website,message:this.message,token:this.token})).then((res) => {
                            if(res.data == 1) {
                                this.reqSuccess = true
                                this.alert = "* The message was successfully sent!";
                            } else {
                                this.reqSuccess = false
                                this.alert = "* There has been an error! Please retry sending the message!";
                            }
                        })
                    }) 
                }

               
            }
           
        },

        async recaptchaToken() {
            const recaptcha =  await load('6LfJuMAaAAAAAOTid5dr8Iku3Wd_jwnpS-W4l9CE',{autoHideBadge: false})
            recaptcha.showBadge()
            let token = await recaptcha.execute('login')

            this.token = token

            setTimeout(function() {
                recaptcha.hideBadge()
            }.bind(this),3000)

        },

        isEmailValid(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            return emailPattern.test(email);
        }
    
    
  },
  mounted() {
   

    }
}
</script>

