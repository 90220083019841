<template>
  <Header1/>
  <div class="page-content animsition">
    <div class="container">
        <div class="portfolio">
            <div class="row">
                <ul class="portfolio-sorting list-inline text-center">
                    <li><a  @click.prevent="filterByValue('all')" href="#" :class="{active: currentCategory === 'all'}">All</a></li>
                    <li v-for="category in categories" :key="category.id"><a :class="{active: category.title_url === currentCategory}" @click.prevent="filterByValue(category.title_url)" href="#" :title="category.title">{{category.name}}</a></li>
                </ul> <!--end portfolio sorting -->
                <ul class="portfolio-items list-unstyled" id="grid">
                    <transition-group name="fade" tag="ul">
                        <li v-for="news in filteredItems" :key="news.id" class="col-md-4 col-sm-4 col-xs-6 fade-item" >
                            <figure class="portfolio-item">
                                <a :href="'/'+news.categ_url+'/'+news.title_url" class="port-img">
                                    <img  class="img-responsive" :src="news.img_url" :title="news.title" :alt="news.title">
                                 
                                </a>
                                <figcaption class="portfolio-text">
                                    <h3>{{news.category}}</h3>
                                    <p>{{news.title}}...</p>
                                </figcaption>
                            </figure>
                        </li>
                    </transition-group>
                   
                </ul> <!--end portfolio grid -->
            </div> <!--end row -->
            <ul class="pagination">
                <li><a @click.prevent="previousPage" :disabled="currentPage === 1" href="#">&#8701;</a></li>
                <li v-for="(page,index) in totalPages" :key="index" :class="{active: page === currentPage}"><a  @click.prevent="changePage(page)" href="#">{{page}} </a></li>
               
              
                <li ><a @click.prevent="nextPage" :disabled="currentPage === newsTotalPages" href="#">&#8702;</a></li>
            </ul>
        </div>
    </div>
</div>
<Footer1/>
</template>

<script>

    import Header1 from '@/components/HeaderFooter/Header1'
    import Footer1 from '@/components/HeaderFooter/Footer1'

    import axios from "axios"
    import php_url from "../main.js"

    export default {
    inheritAttrs: false,
    name: 'NewsCategoryList',
    components: {
        Header1, Footer1
    },
    props: {
        
    },
    data() {
        return {
            currentCategory: "",
            filterValue: '',
            categories: [],
            latestNews: [],
            latestNewsPageSize: 10, 
            currentPage: 1,
            totalPages: "",
            filteredItemsLength: ""
        }
    },
    methods: {

        getCategories() {
            axios.post(php_url.php_url,JSON.stringify({action:"getCategories"})).then((res) => {
                this.categories = res.data;
            })
        },
       

       fetchItems($categ) {
            axios.post(php_url.php_url,JSON.stringify({action:"getLatestNews",length:""})).then((res) => {
                this.latestNews = res.data;
                this.filteredItemsLength = this.latestNews.length;
            })
            this.filterByValue($categ); // Apply initial filtering
        
        },
        
        filterByValue(value) {
            this.currentCategory = value;
            this.filterValue = value;
            this.filteredItemsLength = Math.ceil(this.latestNews.length / this.latestNewsPageSize);
        },
        nextPage() {
        if(this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        },
        previousPage() {
            if(this.currentPage > 1) {
                this.currentPage--;
            }
        },
        changePage(page) {
            this.currentPage = page;
        },
    
    },
    computed:{
        filteredItems() {
            const startIndex = (this.currentPage - 1) * this.latestNewsPageSize;
            const endIndex = startIndex + this.latestNewsPageSize;
          
            if(this.filterValue != "all") {
                
                const filteredItems = this.latestNews.filter(item => item.categ_url === this.filterValue).slice(startIndex, endIndex);
                const itemsLength = filteredItems.length;

                this.filteredItemsLength = itemsLength;

                return filteredItems; 
                
            } else {
                const filteredItems = this.latestNews.slice(startIndex, endIndex);
                const itemsLength = this.latestNews.length;
              
                this.filteredItemsLength = this.latestNews.length;

                return filteredItems;
            }

        },
        newsTotalPages() {
            if(this.filteredItemsLength == 0) {
                this.filteredItemsLength = 1;
            }
            this.totalPages = Math.ceil(this.filteredItemsLength / this.latestNewsPageSize);

            if(this.totalPages == 1) {
                this.currentPage = 1;
            }
            return this.totalPages;
        }
    },
  
    mounted() { 
        const newsCateg = this.$route.params.newsCateg
        this.categ = newsCateg    

        this.getCategories()

        this.fetchItems(this.categ);
                    
        }
    }
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .portfolio-items figure a {
        transition: opacity 0.4s, transform 0.4s;
    }

    .portfolio-items figure a:hover {
        opacity: 0.5;
        transform: scale(1.1);
    }

    .fade-item {
        transition: opacity 0.5s;
    }

    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
    opacity: 0;
    }

    

</style>
