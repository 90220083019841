<template>
    <Header1/>
  <div class="main-content animsition">

    <div class="container">
        <div class="newstricker_inner">
            <div class="trending"><strong>Trending</strong> Now</div>
            <div id="newsTicker" class="owl-carousel owl-theme">
                <div v-show="btcPrice" class="item">
                    <a href="#">BTC: {{btcPrice}} USD | ETH: {{ethPrice}} USD | EGLD: {{egldPrice}} USD</a>
                </div>
                <div v-if="topItems1" class="item">
                    <a :href="'/presale/'+topItems1.title_url">Presale - {{topItems1.title100}}</a>
                </div>
                <div v-if="topItems2.title100" class="item">
                    <a :href="'/presale/'+topItems2.title_url">Presale - {{topItems2.title100}}</a>
                </div>
                <div v-if="topItems3.title100" class="item">
                    <a :href="'/presale/'+topItems3.title_url">Presale - {{topItems3.title100}}</a>
                </div>
            </div>
        </div>
    </div>

   
    <div class="news-masonry">
        <div class="container">
            <div class="row mas-m">
                <div class="col-sm-6 mas-p">
                   
                    <div class="mas-item mas-big">
                        
                        <a :href="newsMain1.title_url"><figure><img :src="newsMain1.img_url" class="img-responsive" :title="newsMain1.title" :alt="newsMain1.title"></figure></a>
                      
                        <div class="mas-text">
                            <div class="post-cat"><a :href="newsMain1.title_url">{{newsMain1.category}}</a></div>
                            <h2 class="mas-title"><a :href="newsMain1.title_url">{{newsMain1.title}}</a></h2>
                            <div class="mas-details">
                               <p><span v-html="newsMain1.short_content"></span>
                                <a :href="newsMain1.title_url" class="read-more">Read More &#8702;</a>
                            </p>
                            </div>
                        </div>
                    </div>
                
                </div>
                <div class="col-sm-6 mas-p">
                    <div class="row mas-m">
                        <div class="col-xs-6 col-sm-6 mas-p">
                            <div class="masonry-slide1 owl-carousel owl-theme">
                                <div class="item mas-m-b">
                                    <div class="mas-item masonry-sm">
                                        <a :href="newsMain3.title_url"><figure><img :src="newsMain3.img_url" class="img-responsive" :title="newsMain3.title" :alt="newsMain3.title"></figure></a>
                                        <div class="mas-text" :title="newsMain3.title">
                                            <div class="post-cat"><a :href="newsMain3.title_url">{{newsMain3.category}}</a></div>
                                            <h4 class="mas-title"><a :href="newsMain3.title_url">{{newsMain3.title}}</a></h4>
                                            <div class="mas-details">
                                                <p><span v-html="newsMain3.short_content"></span>
                                                    <a :href="newsMain3.title_url" class="read-more">Read More &#8702;</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                   
                                </div>
                                <div class="item mas-m-b">
                                    <div class="mas-item masonry-sm">
                                        <a :href="newsMain4.title_url"><figure><img :src="newsMain4.img_url" class="img-responsive" :title="newsMain4.title" :alt="newsMain4.title"></figure></a>
                                        <div class="mas-text">
                                            <div class="post-cat"><a :href="newsMain4.title_url">{{newsMain4.category}}</a></div>
                                            <h4 class="mas-title"><a :href="newsMain4.title_url">{{newsMain4.title}}</a></h4>
                                            <div class="mas-details">
                                                <p><span v-html="newsMain4.short_content"></span>
                                                    <a :href="newsMain4.title_url" class="read-more">Read More &#8702;</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 mas-p">
                            <div class="masonry-slide2 owl-carousel owl-theme">
                                <div class="item">
                                    <div class="mas-item masonry-sm">
                                        <a :href="newsMain5.title_url"><figure><img :src="newsMain5.img_url" class="img-responsive" :title="newsMain5.title" :alt="newsMain5.title"></figure></a>
                                        <div class="mas-text">
                                            <div class="post-cat"><a :href="newsMain5.title_url">{{newsMain5.category}}</a></div>
                                            <h4 class="mas-title"><a :href="newsMain5.title_url">{{newsMain5.title}}</a></h4>
                                            <div class="mas-details">
                                                <p><span v-html="newsMain5.short_content"></span>
                                                    <a :href="newsMain5.title_url" class="read-more">Read More &#8702;</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="item">
                                    <div class="mas-item masonry-sm">
                                        <a :href="newsMain6.title_url"><figure><img :src="newsMain6.img_url" class="img-responsive" :title="newsMain6.title" :alt="newsMain6.title"></figure></a>
                                        <div class="mas-text">
                                            <div class="post-cat"><a :href="newsMain6.title_url">{{newsMain6.category}}</a></div>
                                            <h4 class="mas-title"><a :href="newsMain6.title_url">{{newsMain6.title}}</a></h4>
                                            <div class="mas-details">
                                                <p><span v-html="newsMain6.short_content"></span>
                                                    <a :href="newsMain6.title_url" class="read-more">Read More &#8702;</a>
                                                </p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hidden-xs row mas-m">
                        <div class="col-sm-6 mas-p">
                            <div class="masonry-slide3 owl-carousel owl-theme">
                                <div class="item">
                                    <div class="mas-item masonry-sm">
                                        <a :href="newsMain7.title_url"><figure><img :src="newsMain7.img_url" class="img-responsive" :title="newsMain7.title" :alt="newsMain7.title"></figure></a>
                                        <div class="mas-text">
                                            <div class="post-cat"><a :href="newsMain7.title_url">{{newsMain7.category}}</a></div>
                                            <h4 class="mas-title"><a :href="newsMain7.title_url">{{newsMain7.title}}</a></h4>
                                            <div class="mas-details">
                                                <p><span v-html="newsMain7.short_content"></span>
                                                    <a :href="newsMain7.title_url" class="read-more">Read More &#8702;</a>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div class="item">
                                    <div class="mas-item masonry-sm">
                                        <a :href="newsMain8.title_url"><figure><img :src="newsMain8.img_url" class="img-responsive" :title="newsMain8.title" :alt="newsMain8.title"></figure></a>
                                        <div class="mas-text">
                                            <div class="post-cat"><a :href="newsMain8.title_url">{{newsMain8.category}}</a></div>
                                            <h4 class="mas-title"><a :href="newsMain8.title_url">{{newsMain8.title}}</a></h4>
                                            <div class="mas-details">
                                                <p><span v-html="newsMain8.short_content"></span>
                                                    <a :href="newsMain8.title_url" class="read-more">Read More &#8702;</a>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 mas-p">
                            <div class="masonry-slide4 owl-carousel owl-theme">
                                <div class="item">
                                    <div class="mas-item masonry-sm">
                                        <a :href="newsMain9.title_url"><figure><img :src="newsMain9.img_url" class="img-responsive" :title="newsMain9.title" :alt="newsMain9.title"></figure></a>
                                        <div class="mas-text">
                                            <div class="post-cat"><a :href="newsMain9.title_url">{{newsMain9.category}}</a></div>
                                            <h4 class="mas-title"><a :href="newsMain9.title_url">{{newsMain9.title}}</a></h4>
                                            <div class="mas-details">
                                                <p><span v-html="newsMain9.short_content"></span>
                                                    <a :href="newsMain9.title_url" class="read-more">Read More &#8702;</a>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div class="item">
                                    <div class="mas-item masonry-sm">
                                        <a :href="newsMain10.title_url"><figure><img :src="newsMain10.img_url" class="img-responsive" :title="newsMain10.title" :alt="newsMain10.title"></figure></a>
                                        <div class="mas-text">
                                            <div class="post-cat"><a :href="newsMain10.title_url">{{newsMain10.category}}</a></div>
                                            <h4 class="mas-title"><a :href="newsMain10.title_url">{{newsMain10.title}}</a></h4>
                                            <div class="mas-details">
                                                <p><span v-html="newsMain10.short_content"></span>
                                                    <a :href="newsMain10.title_url" class="read-more">Read More &#8702;</a>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="hidden-xs hidden-sm col-md-2 leftSidebar">
                    <div class="trending-post">
                        <div class="title-holder">
                            <h3 class="title">Presale Tokens</h3>
                            <span class="title-shape title-shape-dark"></span>
                        </div>
                       
                        <div v-for="article in presaleArticles" :key="article.id" class="media single-post">
                            <!-- <div class="entry-meta">
                                <span class="comment-link"><a href="#"><i class="fa fa-comment-o" aria-hidden="true"></i>9 Comments</a></span>
                            </div> -->
                            <a class="media-left" :href="'/presale/'+article.title_url">
                                <img :src="article.img_url" class="media-object" :title="article.title" :alt="article.title">
                            </a>
                            <h4><a :href="'/presale/'+article.title_url">{{article.title100}}</a></h4>
                        </div>
                    </div>
                    
                    <!-- <div class="banner-content">
                        <img src="../assets/imgs/other/ad-180x480-1.png" class="img-responsive" alt="">
                    </div> -->
                    
                </div>
                <main class="col-xs-12 col-sm-8 col-md-7 content p_r_40">
                   
                    <article class="grid_post grid_post_lg text-center">
                        <figure>
                             <a :href="newsMain2.title_url" class="grid_image"><img :src="newsMain2.img_url" class="img-responsive" :title="newsMain2.title" :alt="newsMain2.title"></a>
                            <figcaption>
                                <div class="post-cat"><span>In</span> <a :href="newsMain2.category_url">{{newsMain2.category}}</a></div>
                                <div class="entry-meta">
                                    <span class="entry-date"><i class="fa fa-calendar-o" aria-hidden="true"></i><time :date="newsMain10.created_at">{{newsMain10.date}}</time></span> 
                                  
                                </div>
                                <h3 class="grid_post_title"><a :href="newsMain2.title_url">{{newsMain2.title}}</a></h3>
                                <p>
                                    <span v-html="newsMain2.short_content"></span>
                                    <a :href="newsMain2.title_url" class="btn link-btn btn-outline btn-rounded">Reading &#8702;</a>
                                </p>
                            </figcaption>
                        </figure>
                    </article>
                    
                 
                    <div class="title-holder">
                        <h3 class="title">Latest Articles</h3>
                        <span class="title-shape title-shape-dark"></span>
                    </div>
                    <div v-for="news in lastNewsItems" :key="news.id" class="media meida-md">
                        <div class="media-left">
                            <a :href="'/'+news.categ_url+'/'+news.title_url"><img :src="news.img_url" class="media-object" :title="news.title" :alt="news.title"></a>
                        </div>
                      
                        <div class="media-body">
                            <div class="post-header">
                                <div class="post-cat"><span>In</span> <a :href="news.category_url">{{news.category}}</a></div>
                                <h3 class="media-heading"><a :href="'/'+news.categ_url+'/'+news.title_url">{{news.title}}</a></h3>
                                <div class="entry-meta">
                                    <span class="entry-date"><i class="fa fa-calendar-o" aria-hidden="true"></i><time :date="news.created_at">{{news.date}}</time></span> 
                                   
                                </div>
                              
                            </div>
                            <p><span v-html="news.content100"></span>...</p>
                            <div class="element-block">
                                <a :href="'/'+news.categ_url+'/'+news.title_url" class="btn link-btn btn-outline btn-rounded">Reading &#8702;</a>
                               
                                <div class="post_share">
                                    <a class="smedia facebook fa fa-facebook" href="#"></a>
                                    <a class="smedia twitter fa fa-twitter" href="#"></a>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                   
                    <ul class="pagination">
                        <li><a @click.prevent="previousPage" :disabled="currentPage === 1" href="#">&#8701;</a></li>
                        <li v-for="(page,index) in totalPages" :key="index" :class="{active: page === currentPage}"><a  @click.prevent="changePage(page)" href="#">{{page}} </a></li>
                       
                        <li ><a @click.prevent="nextPage" :disabled="currentPage === newsTotalPages" href="#">&#8702;</a></li>
                    </ul>
                  
                </main>
                <aside class="col-xs-12 col-sm-4 col-md-3 rightSidebar">
                    <div class="latest_post_widget">
                        <div class="title-holder">
                            <h3 class="title">Most Popular</h3>
                            <span class="title-shape title-shape-dark"></span>
                        </div>
                       
                        <div v-for="popular in mostPopular" :key="popular.id" class="media latest_post">
                            <a class="media-left" :href="'/'+popular.categ_url+'/'+popular.title_url">
                                <img :src="popular.img_url" class="media-object" :title="popular.title" :alt="popular.title">
                            </a>
                            <div class="media-body">
                                <h6 class="media-heading"><a :href="'/'+popular.categ_url+'/'+popular.title_url">{{popular.title50}} ...</a></h6>
                                <div class="entry-meta">
                                    <span class="entry-date"><i class="fa fa-calendar-o" aria-hidden="true"></i><time :date="popular.created_at">{{popular.date}}</time></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="social_share_btn">
                        <div class="title-holder">
                            <h3 class="title">Subscribe</h3>
                            <span class="title-shape title-shape-dark"></span>
                        </div>
                        
                        <ul>
                            <li class="li-facebook"><a href="https://www.facebook.com/cryptochainleak" target="_blank"><i class="fa fa-facebook"></i>Facebook</a></li>
                            <li class="li-twitter"><a href="https://twitter.com/chain_leak_news" target="_blank"><i class="fa fa-twitter"></i>Twitter</a></li>
                            <li class="li-youtube"><a href="https://www.youtube.com/channel/UCk0tEP4f1fUm4xhbbkqxy2Q" target="_blank"><i class="fa fa-youtube-play"></i>Youtube</a></li>
                            <li class="li-instagram"><a href="https://www.instagram.com/cryptochainleak" target="_blank"><i class="fa fa-instagram"></i>Instagram</a></li>
                            <li class="li-tiktok"><a href="https://www.tiktok.com/@cryptochainleak" target="_blank">
 
                                <svg xmlns="http://www.w3.org/2000/svg" height="32" width="28" viewBox="0 0 448 512"><path fill="#ffffff" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg>Tiktok</a></li>
                           
                        </ul>
                    </div>
                   
                    <!-- <div class="banner-content">
                        <img src="../assets/imgs/other/ad-300x250-1.png" class="img-responsive center-block" alt="">
                    </div> -->
                    
                    <div class="category_widget">
                        <div class="title-holder">
                            <h3 class="title">Category</h3>
                            <span class="title-shape title-shape-dark"></span>
                        </div>
                       
                        <a v-for="category in categories" :key="category.id" class="category" :href="'/news-category-list/'+category.title_url" :title="category.name+' Category'">
                            <figure><img :src="category.img_url" class="img-responsive"  :title="category.name+' Category'" :alt="category.name+' Category'"></figure>
                        </a>
                       
                      
                    </div>
                   
                </aside>
            </div>
        </div>
        <div class=" container">
            <div class="height_15"></div>
            <!-- <div class="banner-content">
                <img src="../assets/imgs/other/ad-728x90-1.png" class="img-responsive center-block" alt="">
            </div> -->
           
        </div>
        <div class="youtube_video">
            <div class="container">
                <div class="row">
                    <div class="col-sm-8 col-sm-offset-2">
                        <div class="title-holder">
                            <h3 class="title title-white">Featured Videos</h3>
                            <span class="title-shape title-shape-white"></span>
                        </div>
                       
                        <h3 class="video-title">Video</h3>
                        <div class="item vide_post_item">
                            
                            <div class="videoWrapper videoWrapper169 js-videoWrapper">
                               
                                <iframe class="videoIframe js-videoIframe"  allowfullscreen data-src="https://www.youtube.com/embed/2oY6jzDG83M?autoplay=1& modestbranding=1&rel=0&hl=en"></iframe>
                               
                                <button class="videoPoster js-videoPoster" style="background-image:url(${require(@/assets/imgs/other/youtube-video.jpg)});">Play video</button>
                            </div>
                            <!--<button onclick="videoStop()">external close button</button>-->
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
       
        <div class="container">
            <div class="row">
                <div class="hidden-xs hidden-sm col-md-2 leftSidebar">
                    <!-- <div class="banner-content">
                        <img src="../assets/imgs/other/ad-180x480-1.png" class="img-responsive" alt="">
                    </div> -->
                   
                </div>
                <div class="col-xs-12 col-sm-8 col-md-7 content  p_r_40">
                   
                    <!-- <div class="banner-content">
                        <img src="../assets/imgs/other/ad-728x90-1.png" class="img-responsive center-block" alt="">
                    </div> -->
                   
                </div>            
                <div class="col-xs-12 col-sm-4 col-md-3 rightSidebar">
                    <div class="fb_like">
                        <div class="title-holder">
                            <h3 class="title">Facebook</h3>
                            <span class="title-shape title-shape-dark"></span>
                        </div>
                       
                        <div class="fb-page" data-href="https://www.facebook.com/cryptochainleak" data-show-posts="true"  data-width="268" data-height="214" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                            <blockquote cite="https://www.facebook.com/cryptochainleak" class="fb-xfbml-parse-ignore">
                                <a href="https://www.facebook.com/cryptochainleak">Crypto Chain Leak News</a>
                            </blockquote>
                        </div>
                    </div>
                   
                    <!-- <div class="banner-content">
                        <img src="../assets/imgs/other/ad-300x250-1.png" class="img-responsive center-block" alt="">
                    </div> -->

                    <div class="tag_widget">
                        <div class="title-holder">
                            <h3 class="title">Tags</h3>
                            <span class="title-shape title-shape-dark"></span>
                        </div>
                       
                        <a href="/">Cryptocurrency News</a>
                        <a href="/bitcoin">Bitcoin News</a>
                        <a href="/ethereum">Ethereum News</a>
                        <a href="/egold">Egold News</a>
                        <a href="/multiversx">MultiversX News</a>
                        <a href="/binance">Binance News</a>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
  
    
</div>

<Footer1/>
</template>

<style>
    .container main.content a img {
        width:100%;
    }
</style>

<script>

import Header1 from '@/components/HeaderFooter/Header1'
import Footer1 from '@/components/HeaderFooter/Footer1'

import axios from "axios"
import php_url from "../main.js"


export default {
  inheritAttrs: false,
  name: 'HomeView',
  components: {
    Header1, Footer1
  },
  data() {
            return {
                topItems1: {},
                topItems2: {},
                topItems3: {},
                newsMain1: "",
                newsMain2: "",
                newsMain3: "",
                newsMain4: "",
                newsMain5: "",
                newsMain6: "",
                newsMain7: "",
                newsMain8: "",
                newsMain9: "",
                newsMain10: "",
                presaleArticles: "",
                latestNews: [],
                mostPopular: [],
                categories: [],
                latestNewsPageSize: 4, 
                currentPage: 1,
                totalPages: "",               

                btcPrice: "",
                ethPrice: "",
                egldPrice: ""
            }
        },
  props: {
    
  },
  computed: {
    lastNewsItems() {
      const startIndex = (this.currentPage - 1) * this.latestNewsPageSize;
      const endIndex = startIndex + this.latestNewsPageSize;
      return this.latestNews.slice(startIndex, endIndex);
    },
    newsTotalPages() {
        this.totalPages = Math.ceil(this.latestNews.length / this.latestNewsPageSize);
        return this.totalPages;
    },
  },
  methods: {
    nextPage() {
        if(this.currentPage < this.totalPages) {
            this.currentPage++;
        }
    },
    previousPage() {
        if(this.currentPage > 1) {
            this.currentPage--;
        }
    },
    changePage(page) {
      this.currentPage = page;
    },
    getCryptoPrice() {
        axios.post(php_url.php_url,JSON.stringify({action:"getCryptoPrice"})).then((res) => {
            this.btcPrice = res.data.btcPrice;
            this.ethPrice = res.data.ethPrice;
            this.egldPrice = res.data.egldPrice;
          
        })
    },
    getPresaleArticles() {
            axios.post(php_url.php_url,JSON.stringify({action:"getPresaleArticles",length:"4"})).then((res) => {
                this.presaleArticles = res.data;

                const objectPesale = { ...this.presaleArticles };
                
                Object.entries(objectPesale).forEach(([key, value]) => {
                    if(key == 0) {
                        this.topItems1.title100 = value.title100;
                        this.topItems1.title_url = value.title_url;
                    } else if(key == 1) {
                        this.topItems2.title100 = value.title100;
                        this.topItems2.title_url = value.title_url;
                    } else if(key == 2) {
                        this.topItems3.title100 = value.title100;
                        this.topItems3.title_url = value.title_url;
                    }
                });
            })
        },
    getNews() {
            axios.post(php_url.php_url,JSON.stringify({action:"getNews",news_id:"",news_categ:""})).then((res) => {

                this.latestNews = res.data.latestNews;
                this.mostPopular = res.data.mostPopular;
                this.categories = res.data.categories;
            
                this.newsMain1 = res.data.newsMain1
                // if(this.newsMain1.img_url) {
                //     this.newsMain1.img_url =this.newsMain1.img_url
                // }
                
                this.newsMain1.short_content = this.newsMain1.content.substring(0,200)+"...  "
                this.newsMain1.title_url = this.newsMain1.categ_url+"/"+this.newsMain1.title_url

                this.newsMain2 = res.data.newsMain2
                // this.newsMain2.img_url = require("@/assets/"+this.newsMain2.img_url)
                this.newsMain2.title = this.newsMain2.title.substring(0,100)+"..."
                this.newsMain2.short_content = this.newsMain2.content.substring(0,100)+"...  "
                this.newsMain2.title_url = this.newsMain2.categ_url+"/"+this.newsMain2.title_url

                this.newsMain3 = res.data.newsMain3
                // this.newsMain3.img_url = require("@/assets/"+this.newsMain3.img_url)
                this.newsMain3.title = this.newsMain3.title.substring(0,100)+"..."
                this.newsMain3.short_content = this.newsMain3.content.substring(0,100)+"...  "
                this.newsMain3.title_url = this.newsMain3.categ_url+"/"+this.newsMain3.title_url

                this.newsMain4 = res.data.newsMain4
                // this.newsMain4.img_url = require("@/assets/"+this.newsMain4.img_url)
                this.newsMain4.title = this.newsMain4.title.substring(0,100)+"..."
                this.newsMain4.short_content = this.newsMain4.content.substring(0,100)+"...  "
                this.newsMain4.title_url = this.newsMain4.categ_url+"/"+this.newsMain4.title_url

                this.newsMain5 = res.data.newsMain5
                // this.newsMain5.img_url = require("@/assets/"+this.newsMain5.img_url)
                this.newsMain5.title = this.newsMain5.title.substring(0,100)+"..."
                this.newsMain5.short_content = this.newsMain5.content.substring(0,100)+"...  "
                this.newsMain5.title_url = this.newsMain5.categ_url+"/"+this.newsMain5.title_url

                this.newsMain6 = res.data.newsMain6
                // if(this.newsMain6.img_url) {
                //     this.newsMain6.img_url = require("@/assets/"+this.newsMain6.img_url)
                // }
                if(this.newsMain6.title) {
                    this.newsMain6.title = this.newsMain6.title.substring(0,100)+"..."
                    this.newsMain6.short_content = this.newsMain6.content.substring(0,100)+"...  "
                }
                this.newsMain6.title_url = this.newsMain6.categ_url+"/"+this.newsMain6.title_url

                this.newsMain7 = res.data.newsMain7
                // if(this.newsMain7.img_url) {
                //     this.newsMain7.img_url = require("@/assets/"+this.newsMain7.img_url)
                // }
                if(this.newsMain7.title) {
                    this.newsMain7.title = this.newsMain7.title.substring(0,100)+"..."
                    this.newsMain7.short_content = this.newsMain7.content.substring(0,100)+"...  "
                }
                this.newsMain7.title_url = this.newsMain7.categ_url+"/"+this.newsMain7.title_url

                this.newsMain8 = res.data.newsMain8
                // if(this.newsMain8.img_url) {
                //     this.newsMain8.img_url = require("@/assets/"+this.newsMain8.img_url)
                // }
                if(this.newsMain8.title) {
                    this.newsMain8.title = this.newsMain8.title.substring(0,100)+"..."
                    this.newsMain8.short_content = this.newsMain8.content.substring(0,100)+"...  "
                }
                this.newsMain8.title_url = this.newsMain8.categ_url+"/"+this.newsMain8.title_url

                this.newsMain9 = res.data.newsMain9
                // if(this.newsMain9.img_url) {
                //     this.newsMain9.img_url = require("@/assets/"+this.newsMain9.img_url)
                // }
                if(this.newsMain9.title) {
                    this.newsMain9.title = this.newsMain9.title.substring(0,100)+"..."
                    this.newsMain9.short_content = this.newsMain9.content.substring(0,100)+"...  "
                }
                this.newsMain9.title_url = this.newsMain9.categ_url+"/"+this.newsMain9.title_url


                this.newsMain10 = res.data.newsMain10
                // if(this.newsMain10.img_url) {
                //     this.newsMain10.img_url = require("@/assets/"+this.newsMain10.img_url)
                // }
                if(this.newsMain10.title) {
                    this.newsMain10.title = this.newsMain10.title.substring(0,200)+"..."
                    this.newsMain10.short_content = this.newsMain10.content.substring(0,300)+"...  "
                }
                this.newsMain10.title_url = this.newsMain10.categ_url+"/"+this.newsMain10.title_url
                this.newsMain10.category_url = "/news-category-list/"+this.newsMain10.categ_url

                
                })
            },
        },
   
  mounted() {
    this.getNews()
    this.getPresaleArticles()
    this.getCryptoPrice()
    }
}
</script>
