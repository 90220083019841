<template>
    <div class="newslatter">
        <div class="container">
            <h3>Sign Up for Our <em>Newsletter</em></h3>
            <p>Subscribe now to get notified about Cryptocurrency news!</p>
            <div class="navbar-form" role="search">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Your email address" name="q" v-model="email">
                    <div class="input-group-btn">
                        <button @click="submitSubscription" class="btn btn-default" >Sign Up</button>
                    </div>
                </div>
                <p v-if="alert" class="alertMessage" :class="reqSuccess ? 'alert-success' : 'alert-danger'">{{alert}}</p>
            </div>
        </div>
    </div>
    <!-- /. End of news latter -->
    <div style="background-image: url(../imgs/crypto_btc.jpg);height: 120px;" class="insta-content">
        <div class="insta-link"><a href="#" rel="me" target="_blank" class="">Follow Us!</a></div>
        <!-- <img class="ri-loading-image" src="../../assets/imgs/other/loading.gif" alt=""/> -->
        <!-- <div id="ri-grid" class="ri-grid">
            
            <ul>
                <li><a href="#"><img src="../../assets/imgs/other/rep_footer.jpg" alt=""/></a></li>
                <li><a href="#"><img src="../../assets/imgs/other/rep_footer.jpg" alt=""/></a></li>
                <li><a href="#"><img src="../../assets/imgs/other/rep_footer.jpg" alt=""/></a></li>
                <li><a href="#"><img src="../../assets/imgs/other/rep_footer.jpg" alt=""/></a></li>
                <li><a href="#"><img src="../../assets/imgs/other/rep_footer.jpg" alt=""/></a></li>
                <li><a href="#"><img src="../../assets/imgs/other/rep_footer.jpg" alt=""/></a></li>
                <li><a href="#"><img src="../../assets/imgs/other/rep_footer.jpg" alt=""/></a></li>
                <li><a href="#"><img src="../../assets/imgs/other/rep_footer.jpg" alt=""/></a></li>
               
            </ul>
        </div> -->
    </div>
    <!-- /. End of instagram -->
    <footer class="footer-black">
        <div class="main-footer">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 col-md-4">
                        <div class="footer-box">
                            <div class="footer-logo">
                                <img src="../../assets/imgs/other/logo-white.png" class="img-responsive" title="Crypto Chain Leak" alt="Crypto Chain Leak">
                            </div>
                            <p>Crypto Chain Leak is a comprehensive website dedicated to delivering the latest and most relevant news and updates from the dynamic world of cryptocurrencies. Whether you're an avid investor, a technology enthusiast, or simply curious about the fascinating realm of digital currencies, Crypto Chain Leak is your go-to source for staying informed. <br>Stay ahead of the curve and explore the future of finance with Crypto Chain Leak.</p>
                            
                            <p>Contact us on <a href="mailto:contact@cryptochainleak.com"><strong>contact@cryptochainleak.com</strong></a></p> 
                        </div>
                    </div>
                  
                    <div class="col-sm-4 col-md-4">
                        <div class="footer-box">
                            <h3 class="widget-title title-white">Presale Tokens</h3>
                            <div v-for="presale in presaleArticles" :key="presale.id" class="media latest_post">
                                <a class="media-left" :href="'/presale/'+presale.title_url">
                                    <img :src="presale.img_url" class="media-object" :title="presale.title" :alt="presale.title">
                                </a>
                                <div class="media-body">
                                    <h6 class="media-heading"><a :href="'/presale/'+presale.title_url">{{presale.title50}} ...</a></h6>
                                    <div class="entry-meta">
                                        <span class="entry-date"><i class="fa fa-calendar-o" aria-hidden="true"></i><time :date="presale.created_at">{{presale.date}}</time></span>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4">
                        <div class="footer-box">
                            <h3 class="widget-title title-white">Latest Post</h3>
                            <div v-for="news in latestNews" :key="news.id" class="media latest_post">
                                <a class="media-left" :href="'/'+news.categ_url+'/'+news.title_url">
                                    <img :src="news.img_url" class="media-object" :title="news.title" :alt="news.title">
                                </a>
                                <div class="media-body">
                                    <h6 class="media-heading"><a :href="'/'+news.categ_url+'/'+news.title_url">{{news.title50}} ...</a></h6>
                                    <div class="entry-meta">
                                        <span class="entry-date"><i class="fa fa-calendar-o" aria-hidden="true"></i><time :date="news.created_at">{{news.date}}</time></span>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-footer">
            Copyright © {{currentYear}} by CryptoChainLeak. All Rights Reserved.
        </div>
    </footer>

</template>

<style>
    .newslatter .alertMessage {
        margin-top: 10px;
        font-size: 18px;
        padding: 5px;
        font-family: sans-serif;
    }


    .newslatter .alert-danger {
        color: #ff0000;
    }

    .newslatter .alert-success {
        color: #1c8a00;
    }

</style>

<script>

    import axios from "axios"
    import php_url from "../../main.js"

    import { load } from 'recaptcha-v3'
   
    export default {
      name: 'Footer1',
      props: {
       
      },
      data() {
            return {
                latestNews: [],
                presaleArticles: [],
                currentYear: "",
                email: "",
                alert: "",
                reqSuccess: false,
                token: ""
            }
        },
      methods: {
        submitSubscription() {
            if(this.email === "") {
                this.reqSuccess = false
                this.alert = "* Email field is mandatory!";
            } else if(!this.isEmailValid(this.email)) {
                this.reqSuccess = false
                this.alert = "* The email is not in the right format!";
            } else {
              
                this.recaptchaToken().then(() => {

                    axios.post(php_url.php_url,JSON.stringify({action:"submitSubscription",email:this.email,token:this.token})).then((res) => {
                        if(res.data == 1) {
                            this.alert = "Message submited successfully!";
                            this.reqSuccess = true
                        } else if(res.data == 2) {
                            this.alert = "Email already subscribed!";
                            this.reqSuccess = false
                        } else {
                            this.alert = "There was a problem submitting the email. Please try again!";
                            this.reqSuccess = false
                        }
                    })
                })
            }

        },
        getLatestNews() {
            axios.post(php_url.php_url,JSON.stringify({action:"getLatestNews",length:"3"})).then((res) => {
                this.latestNews = res.data;
            })
        },
        getPresaleArticles() {
            axios.post(php_url.php_url,JSON.stringify({action:"getPresaleArticles",length:"3"})).then((res) => {
                this.presaleArticles = res.data;
            })
        },
        currentDate() {
            let dateObj = new Date();

            this.currentYear = dateObj.getFullYear()
        },
        async recaptchaToken() {
            const recaptcha =  await load('6LfJuMAaAAAAAOTid5dr8Iku3Wd_jwnpS-W4l9CE',{autoHideBadge: false})
            recaptcha.showBadge()
            let token = await recaptcha.execute('login')

            this.token = token

            setTimeout(function() {
                recaptcha.hideBadge()
            }.bind(this),3000)

        },

        isEmailValid(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            return emailPattern.test(email);
        }
      },        
      mounted() {
        this.getLatestNews()
        this.currentDate()
        this.getPresaleArticles()
        }   
    }
</script>